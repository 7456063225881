export default function (file) {
  return new Promise(function (resolve, reject) {
    let data = '';
    const video = document.createElement("video");
    video.setAttribute('crossOrigin', 'anonymous');//处理跨域
    video.setAttribute('src', URL.createObjectURL(file));
    video.setAttribute('width', 400);
    video.setAttribute('height', 240);
    video.setAttribute('preload', 'auto');
    video.addEventListener('loadeddata', function () {
      const canvas = document.createElement("canvas"),
      width = video.width, //canvas的尺寸和图片一样
      height = video.height;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
      canvas.toBlob(function (e) {
        const file = new File([e], Date.now() + '.jpg', {
          type: e.type ? e.type : 'image/png',
          lastModified: Date.now()
        })
        resolve(file)
      }, 'image/png', 1)
    });
  })
}