import ImageCompressor from 'js-image-compressor'

export default function (file, maxWidth, maxHeight) {
  return new Promise((resolve,reject) => {
    new ImageCompressor({
      file: file,
      quality: 0.8,
      maxWidth,
      maxHeight,
      convertSize: 1000000,
      redressOrientation: false,
      success: function (result) {
        console.log(result);
        resolve(new File([result], result.name ? result.name : Date.now() + '.jpg', {
          type: result.type ? result.type : 'image/png',
          lastModified: Date.now()
        }))
      },
      error (e) {
        reject(e)
      }
    })
  }).catch(err => {
    throw err
  })
}