<template>
  <div>
    <el-drawer :visible.sync="params.show" :before-close="close" size="30%" @open="openDialog"  :modal="false" :with-header="false">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="80px">
        <el-form-item label="类型" prop="type">
          <el-select clearable v-model="ruleForm.type" placeholder="请选择类型">
            <el-option label="图片" :value="1"></el-option>
            <el-option label="视频" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件" prop="file_path">
          <image-upload @file="fileChange" :type="ruleForm.type == 1 ? 'image' : 'video'" v-model="ruleForm.file_path"></image-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>

import { addProductSlideshow } from '@/api/product/product'

import imageUpload from '@/components/imageupload/image-upload.vue'
import imageCompressor from '@/utils/imageCompressor'
import screenshot from '@/utils/screenshot'
import { upload_image } from '@/api/upload/upload'
export default {
  components: {
    imageUpload
  },
  data() {
    return {
      ruleForm: {
        product_id: '',
        type: 1,
        file_path: '',
        cover_path: '',
      },
      rules: {
        file_path: [
          { required: true, message: '请输上传文件', trigger: 'blur' },
        ],
      }
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.$emit('close')
    },
    async openDialog () {
      this.ruleForm.product_id = this.params.data.product_id
    },
    async fileChange(file) {
      try {
        let newFile = null
        if (this.ruleForm.type == 1) {
          newFile = await imageCompressor(file, 720)
        } else {
          const imageBolb = await screenshot(file)
          newFile = await imageCompressor(imageBolb, 720)
        }
        // 上传
        const result = await upload_image(newFile)
        if (result.success) {
          this.ruleForm.cover_path = result.data.visit_path
        }
      } catch (err) {
        this.$message.error(err.message)
        throw err
      }
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) return
        const result = await addProductSlideshow(this.ruleForm)
        if (result.success) {
          this.$message.success('添加成功')
          this.close()
        }
      })
    },
  },
  computed: {
    
  },
}
</script>
<style scoped lang="less">
  .el-form {
    margin: 10px;
  }
</style>
