export let propList = [
  { prop: 'id',label: 'ID', align: 'center', width: '80', webId: 1, fixed: 'left', },
  { prop: 'cover_path', label: '封面', minWidth: '100', align: 'center', slotName: 'coverSlot', showOverflowTooltip: true, webId: 2, },
  { prop: 'type', label: '类型', minWidth: '150', align: 'center', showOverflowTooltip: true, slotName: 'typeSlot', webId: 3},
  { prop: 'create_time', label: '创建时间', minWidth: '200', align: 'center', dateFormat: 'dateTime', showOverflowTooltip: true, webId: 4 },
  { prop: 'update_time', label: '更新时间', minWidth: '200', align: 'center', dateFormat: 'dateTime', showOverflowTooltip: true, webId: 5 }
]
export let operationProps = {
  isShow: true, //设置后面的选择选择条例出不出现
  checkedCities: [1, 2, 3, 4, 5], //配置默认出现的条例对应上方的webId
  checkeList: [
    //配置有哪些条咧id对应上方的webId
    { id: 1, label: 'ID' },
    { id: 2, label: '封面' },
    { id: 3, label: '类型' },
    { id: 4, label: '创建时间' },
    { id: 5, label: '更新时间' },
  ],
  width: 200, //配置选择栏的样式 宽带是多少
  align: 'center' //字体居中
}
export let otherProps = {
  showSelectColumn: false, //是否显示多选框
  showFooter: false //显示底部分页
}
