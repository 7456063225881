<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/main/product">产品管理</el-breadcrumb-item>
        <el-breadcrumb-item>产品主图</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row>
      <el-col :span="24">
        <el-button type="primary" @click="addClick">添加</el-button>
      </el-col>
    </el-row>

    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="slideshowList"
      v-bind="otherProps"
    >
      <template #typeSlot="scope">
        {{ scope.row.type == 1 ? '图片' : '视频' }}
      </template>
      <template #coverSlot="scope">
        <el-image slot="error" :src="scope.row.cover_path" >
          <i class="el-icon-picture-outline"></i>
        </el-image>
      </template>
      <template #operation="scope">
        <a :href="scope.row.file_path" target="_blank" style="color: blue;margin-right: 5px; cursor: pointer;">查看</a>
        <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
          <span slot="reference" style="color: red;cursor: pointer;">删除</span>
        </el-popconfirm>
      </template>
    </table-ui>

    <add-slideshow-drawer :params="addInfo" @close="addDialogClose"></add-slideshow-drawer>
  </div>
</template>

<script>
import TableUi from '@/components/table-ui/table-ui.vue'
import { propList, operationProps, otherProps } from './config/slideshowConfig'
import { productDetail, deleteProductSlideshow } from '@/api/product/product'
import addSlideshowDrawer from './cnps/add-slideshow-drawer.vue'
export default {
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      id: 0,
      slideshowList: [],
      addInfo: {
        show: false,
        data: null,
      },
      queryInfo: {
        cur: 1,
        size: 20,
      }
    }
  },
  components: {
    TableUi,
    addSlideshowDrawer
  },
  mounted() {
    this.id = this.$route.query.id
    this.getDataList()
  },
  computed: {
  },
  methods: {
    async getDataList () {
      const result = await productDetail({ id: this.id });
      if (result.success) {
        this.slideshowList = result.data.images
      }
    },
    // 增加
    addClick() {
      this.addInfo.show = true
      this.addInfo.data = { product_id: this.id }
    },
    // 关闭窗口
    addDialogClose () {
      this.addInfo.show = false
      this.addInfo.data = null
      this.getDataList()
    },
    // 删除
    async deleteClick(id) {
      const result = await deleteProductSlideshow(id)
      if (result.success) {
        this.$message.success('删除成功')
        this.getDataList()
      }
    }
  },
  
}
</script>

<style lang="less" scoped>

</style>